import React from "react"
import { Link } from "gatsby"

import headerStyles from './header.module.scss'

const Header = (props) => {

    return (
        <header  className="section-container">
            <h1 className={headerStyles.siteTitle}>
                <Link to="/"><span className={headerStyles.product}>Product</span><span className={headerStyles.skills}>Skills</span></Link>
            </h1>
            <nav><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/"></Link></nav>
            {props.children}
        </header>
    )
}

export default Header