import React from "react"
import { Link } from "gatsby"

import footerStyles from './footer.module.scss'

/* const twitterIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="328 355 335 276">
    <path
      fill="#5f6c80"
      d="M630 425a195 195 0 01-299 175 142 142 0 0097-30 70 70 0 01-58-47 70 70 0 0031-2 70 70 0 01-57-66 70 70 0 0028 5 70 70 0 01-18-90 195 195 0 00141 72 67 67 0 01116-62 117 117 0 0043-17 65 65 0 01-31 38 117 117 0 0039-11 65 65 0 01-32 35z"
    ></path>
    </svg>
); */

const linkArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="12px" height="16px" viewBox="0 -15 30 100" xmlSpace="preserve">
        <polyline fill="none" stroke="#00DB00" strokeWidth={15} strokeLinecap="round" strokeLinejoin="round" points="
	0.375,0.375 45.63,38.087 0.375,75.8 " />
      </svg>
  );

const Footer = () => {
    return (
        <footer >
            <div className="section-container">
                <div className={footerStyles.footerSection}>
                    <h3>Resources</h3>
                    <ul>
                        <li><Link to="/newsletters"><span role="img" aria-label="newsletter">📨</span>PM Newsletters</Link></li>
                        {/* <li ><Link to="/toolbox"><span role="img" aria-label="toolbox">🧰</span>PM Toolbox</Link></li> */}
                        {/* <li className={footerStyles.social}><a href="https://twitter.com/PMskillsOnline" target="_blank" rel="noreferrer">{twitterIcon}<span className={footerStyles.product}>Product</span><span className={footerStyles.skills}>Skills</span> <span className={footerStyles.twitter}>on Twitter</span></a></li> */}

                    </ul>
                    <h3 className={footerStyles.spacer}>About</h3>
                    <ul>
                        <li><Link to="/posts/what-is-this-place"><span role="img" aria-label="garden">🌿</span>What is this place?</Link></li>
                        <li><Link to="/jason"><span role="img" aria-label="person">👋</span>Hey, I'm Jason</Link></li>
                    </ul>

                </div>
                <div className={footerStyles.footerSection}>
                    <h3>Skills</h3>
                    <ul>
                        <li><Link to="/skills/product-sense/"><span role="img" aria-label="magic">✨</span>Product Sense</Link></li>
                        <li><Link to="/skills/analytical-sense/"><span role="img" aria-label="chart">📈</span>Analytical Sense</Link></li>
                        <li><Link to="/skills/execution-sense/"><span role="img" aria-label="check">✅</span>Execution Sense</Link></li>
                        <li className={footerStyles.listSection}>COMING SOON</li>
                        <li ><span role="img" aria-label="crystal-ball">🔮</span>Vision & Strategy</li>
                        <li ><span role="img" aria-label="wealth">💰</span>Commercial Sense</li>
                        <li ><span role="img" aria-label="boss">👔</span>PM Management</li>
                    </ul>

                </div>
                <div className={footerStyles.footerSection}>
                    <h3>Newsletter</h3>
                    <p>Get the best stuff on product delivered to your inbox once a month</p>
                    <iframe title="newsletter" src="https://productskills.substack.com/embed" frameBorder="0" scrolling="no"></iframe>
                    <p><a className={footerStyles.newsletterLink} href="https://productskills.substack.com/" target="_blank" rel="noreferrer">see it first<span>{linkArrow}</span></a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer